.rtc-footer {
  text-align: center;
  background-color: #141414;
}

.footer-text {
  margin: 0;
  font-size: calc(7px + 1vmin);
}

.footer-subtitle {
  font-size: calc(5px + 1vmin);
  color: #bbbbbb;
  margin: 5px 0 0;
}
