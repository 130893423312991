.App-logo {
  height: 45px;
  pointer-events: none;
  margin: 1rem;
  padding-left: 4vw;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 10vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: left;
  font-size: calc(8px + 2vmin);
}
